import React from 'react';

const image1 = '../img/icons/1.png';
const image2 = '../img/icons/2.png';
const image3 = '../img/icons/3.png';
const image4 = '../img/icons/4.png';


const Section = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="subtitle mb-3">Incredibly features</div>
                    <h2 className="mb20">Premium Game Server</h2>
                </div>

                <div className="col-lg-6"></div>

                <div className="col-lg-3 col-md-6 mb-sm-20" >
                    <div>
                        <img src={image1} className="mb20" alt=""/>
                        <h4>Super Quick Setup</h4>
                        <p>Enjoy a hassle-free setup with our streamlined process. Get started quickly and easily with minimal configuration required, so you can focus on what matters most.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src={image2} className="mb20" alt=""/>
                        <h4>Premium Hardware</h4>
                        <p>Experience top performance with our high-quality hardware. Our servers are equipped with the latest technology to ensure reliability, speed, and efficiency.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src={image3} className="mb20" alt=""/>
                        <h4>DDos Protection</h4>
                        <p>Stay secure with our advanced DDoS protection. Safeguard your network from attacks with our robust security measures designed to keep your services running smoothly.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src={image4} className="mb20" alt=""/>
                        <h4>Fast Support</h4>
                        <p>Receive prompt assistance with our dedicated support team. We’re here to provide quick and effective solutions to any issues you encounter, ensuring you have the help you need.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section;