import React from 'react';
import { Link } from "react-router-dom";

const image1 = '../img/covers/1.webp';
const image2 = '../img/covers/2.webp';
const image3 = '../img/covers/3.webp';
const image4 = '../img/covers/4.webp';
const image5 = '../img/covers/5.webp';
const image6 = '../img/covers/6.webp';
const image7 = '../img/covers/7.webp';
const image8 = '../img/covers/8.webp';

const collection = () => {
    return(
        <div className="container">
            <div className="row g-4 sequence">

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Minecraft</h4>
                <p className="d-price">Starting at <span className="price">$3.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img   style={{
width: '301px',
height: '401.33px',
backgroundSize: 'cover'
}} src="https://vollversion-kaufen.de/tpl/images/produkte/MinecraftJavaEditionPCCDKey.webp" className="img-fluid " alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Unturned</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img style={{
width: '301px',
height: '401.33px',
backgroundSize: 'cover'
}} src="https://store-images.s-microsoft.com/image/apps.49692.14053918881081096.5c7f869e-3a72-49b1-bee7-43f4b51af93c.e7fc2e96-c6e3-45e5-aba6-2e279e3466b0" className="img-fluid " alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Counter Strike</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
        style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://de.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fblog%2F1696414021417.jpg&w=1920&q=75" className="img-fluid " alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Rust</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
        style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      
      src="https://assets-prd.ignimgs.com/2021/12/07/rust-1638841834256.png" className="img-fluid " alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>garry's mod</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
        style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://assets-prd.ignimgs.com/2022/07/09/garrysmod-1657387262990.jpg" className="img-fluid " alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Valheim</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
        style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://www.igroshop.com/images/detailed/13/valheim_box_art.png" className="img-fluid " alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>DayZ</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
        style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://image.api.playstation.com/vulcan/ap/rnd/202301/0312/z9AGStZnUt3o3nbxJUrCjkyM.png" className="img-fluid " alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Squad</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://gpstatic.com/acache/36/69/1/de/packshot-5762746b2a75a4ce0699d53067329ad7.jpg" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Arma 3</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://store.bistudio.com/cdn/shop/products/store_bistudio_com-ARMA3game01-1080x108005_2022.jpg?v=1652375580" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item ">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Ark: Survival Evolved</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://image.api.playstation.com/cdn/EP0688/CUSA06782_00/4Fn75AWWgOLZiFdxIA6HvSV5wC9c3WGp.png" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>7 Days to Die</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://pbs.twimg.com/profile_images/1517537104645001217/cI1wdSJR_400x400.png" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Satisfactory</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://m.media-amazon.com/images/I/61767GxojPL._UF1000,1000_QL80_.jpg" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Left for Dead 2</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/550/header.jpg" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item ">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>V Rising</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://sm.ign.com/ign_de/cover/v/v-rising/v-rising_ubj4.jpg" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Space Engineers</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYKpFOYwrLQa0H1crv54alJcB-E5lu0WsDJQ&s" className="img-fluid" alt=""/>
    </div>
</div>

<div className="col-lg-3 col-md-6 gallery-item sandbox">
    <div className="de-item wow">
        <div className="d-overlay">
            <div className="d-label">
                70% OFF
            </div>
            <div className="d-text">
                <h4>Risk of Rain 2</h4>
                <p className="d-price">Starting at <span className="price">$4.00</span></p>
                <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
            </div>
        </div>
        <img 
         style={{
            width: '301px',
            height: '401.33px',
        backgroundSize: 'cover'
      }}
      src="https://upload.wikimedia.org/wikipedia/en/c/c1/Risk_of_Rain_2.jpg" className="img-fluid" alt=""/>
    </div>
</div>

</div>

        </div>
    );
}

export default collection;