import React from 'react';
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";

const image1 ="./img/background/1.webp";

const Section = () => {
    return(
        <div className="container position-relative">
            <div className="row">
                <div className="col-lg-12">
                    <Parallax className="p-0" bgImage={image1} strength={300}>  
                    <div className="padding60 sm-padding40 sm-p-2 position-relative">
                        
                        <div className="row z-1">
                            <div className="col-lg-6">
                                <div className="subtitle mb-3">Start your game</div>
                                <h2 className="wow fadeInUp" data-wow-delay=".2s">
                                Unlock Your Gaming Full Potential</h2>
                                <p className="wow fadeInUp">Experience gaming like never before with ArkadeOne. We bring you the latest in performance-enhancing technology to boost your gameplay and take you to the next level. Whether you're here for fun or to compete, we’ve got you covered with innovative solutions tailored to your needs.</p>
                                <div className="spacer-10"></div>
                                <Link className="btn-main mb10" to="/games">Check out Gameservers</Link>
                            </div>
                        </div>
                        
                    </div>
                    </Parallax>
                </div>
            </div>
        </div>
    );
}

export default Section;