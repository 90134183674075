import React from 'react';

const help = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-6">                            
                    <div className="subtitle  mb-3">Do you have</div>
                    <h2 className=" mb20">Any questions?</h2>
                </div>

                <div className="clearfix"></div>

                <div className="col-lg-6">

                    <div className="accordion accordion-flush" id="accflush">

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c1" aria-expanded="false" aria-controls="flush-c1">
                            Why should i choose ArkadeONE?
                          </button>
                        </h3>
                        <div id="flush-c1" className="accordion-collapse collapse" aria-labelledby="flush-h1" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>Choosing ArkadeONE for game hosting offers several compelling benefits: high performance with the latest hardware, affordability with competitive pricing, a user-friendly interface, customization options, robust security with custom DDoS protection, real-time support with AI integration, and a fun, community-oriented environment with classic arcade game theme. These features make ArkadeONE a strong contender for anyone looking to host game servers.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c2" aria-expanded="false" aria-controls="flush-c2">
                            Does ArkadeONE offer any unique features?
                          </button>
                        </h3>
                        <div id="flush-c2" className="accordion-collapse collapse" aria-labelledby="flush-h2" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>Yes, they offer an an Artifical Intelligence support bot to fix problem in your console.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c3" aria-expanded="false" aria-controls="flush-c3">
                            What kind of support does ArkadeONE offer?
                          </button>
                        </h3>
                        <div id="flush-c3" className="accordion-collapse collapse" aria-labelledby="flush-h3" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>With AI integration, you can get real-time server help whenever you need it.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h4">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c4" aria-expanded="false" aria-controls="flush-c4">
                            How secure are ArkadeONE’s game servers?
                          </button>
                        </h3>
                        <div id="flush-c4" className="accordion-collapse collapse" aria-labelledby="flush-h4" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>ArkadeONE provides custom DDoS protection to keep your game servers safe and secure.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h5">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c5" aria-expanded="false" aria-controls="flush-c5">
                            Can I customize my game server with ArkadeONE?
                          </button>
                        </h3>
                        <div id="flush-c5" className="accordion-collapse collapse" aria-labelledby="flush-h5" data-bs-parent="#accflush">
                          <div className="accordion-body">
                               <p>Absolutely! You can choose from pre-configured game servers or create your own to meet your unique requirements.</p>
                          </div>
                        </div>
                      </div>

                    </div>
                    
                </div>

                <div className="col-lg-6">
                    <div className="accordion accordion-flush" id="accflush1">

                        <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h1-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c1-1" aria-expanded="false" aria-controls="flush-c1-1">
                            How user-friendly is ArkadeONE’s interface?
                          </button>
                        </h3>
                        <div id="flush-c1-1" className="accordion-collapse collapse" aria-labelledby="flush-h1-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                             <p>Their GamePanel is designed to be intuitive and easy to use, catering to both beginners and advanced users.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h2-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c2-1" aria-expanded="false" aria-controls="flush-c2-1">
                            Is ArkadeONE affordable?
                          </button>
                        </h3>
                        <div id="flush-c2-1" className="accordion-collapse collapse" aria-labelledby="flush-h2-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                              <p>Yes, ArkadeONE prioritizes competitive pricing, making high-quality game hosting accessible to everyone.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h3-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c3-1" aria-expanded="false" aria-controls="flush-c3-1">
                            What types of games can I host with ArkadeONE?
                          </button>
                        </h3>
                        <div id="flush-c3-1" className="accordion-collapse collapse" aria-labelledby="flush-h3-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                             <p>ArkadeONE supports a wide range of games, including popular titles like Minecraft, ARK: Survival Evolved, Rust, and many more.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h4-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c4-1" aria-expanded="false" aria-controls="flush-c4-1">
                            How quickly can I set up a game server with ArkadeONE?
                          </button>
                        </h3>
                        <div id="flush-c4-1" className="accordion-collapse collapse" aria-labelledby="flush-h4-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                                <p>Setting up a game server with ArkadeONE is fast and straightforward, often taking just a few minutes thanks to their streamlined setup process.</p>
                            </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h5-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c5-1" aria-expanded="false" aria-controls="flush-c5-1">
                            Does ArkadeONE offer any backup solutions?
                          </button>
                        </h3>
                        <div id="flush-c5-1" className="accordion-collapse collapse" aria-labelledby="flush-h5-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                                <p>Yes, ArkadeONE provides automated backup solutions to ensure your game data is always safe and can be restored easily if needed.</p>
                            </div>
                        </div>
                      </div>

                    </div>
                </div>


            </div>
        </div>
    );
}

export default help;