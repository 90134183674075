import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Parallax } from "react-parallax";
import Navbar from '../layout/Navbar';
import Preloader from '../layout/preloader';
import Blog from '../section-pages/Blog-no-title';
import Footer from '../section-pages/footer';
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';

const image1 ="../../img/background/subheader-news.webp";

const GlobalStyles = createGlobalStyle`

`;

export default function Home() {
  useEffect(() => {
      if (typeof window !== 'undefined') {
          const loader = document.getElementById('mainpreloader');
          if (loader)
          setTimeout(() => {
            loader.classList.add("fadeOut");
            loader.style.display = 'none';
          }, 600)
      }
    }, []);
  return (
    <>
    {/* HEAD */}
    <Helmet>
      <link rel="icon" href="./img/icon.png" />
      <title>ArkadeONE - Terms</title>
    </Helmet>

    <GlobalStyles/>

    {/* LOADER */}
    <div id='mainpreloader'>
      <Preloader/>
    </div>

    {/* MENU */}
    <div className="home dark-scheme">
      <header id="header-wrap">
         <Navbar />
      </header>

      {/* section */}
      <Parallax className="" bgImage={image1} strength={5}>  
        <section className="no-bg">
          <div className="container z-9">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="subtitle wow fadeInUp mb-3">Latest From Us</div>
                  </div>
                  <div className="col-lg-6">
                      <h2>Terms of Service & Privacy Policy</h2>
                  </div>                 
              </div>
          </div>
        </section>
      </Parallax>
      <div className="container-fluid">
        {/* Content */}
        <div className="content">
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="introduction-section">1. Introduction</h2>
            {/* para-1 */}
            <p className="para-1">Welcome to ArkadeONE Hosting. By using our services, you agree to these Terms of Service (the "Terms") outlined in this document. Please read them carefully. If you do not accept these Terms, you must not use our services.</p>
          </section>
          {/* Account Eligibility */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="services-section">2. Services</h2>
            {/* para-1 */}
            <p className="para-1">ArkadeONE Hosting provides game server hosting solutions, including server rental, management tools, and support services. We reserve the right to modify or discontinue any aspect of our services at any time, with or without notice.
            </p>
          </section>
          {/* Account Registration */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="account-registration-section">3. Account Registration</h2>
            {/* para-1 */}
            <p className="para-1">To use our services, you must create an account with ArkadeONE Hosting. You agree to provide accurate and complete information when creating your account and to keep this information up-to-date.</p>
          </section>
          {/* User Conduct */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="user-conduct-section">4. User Conduct</h2>
            {/* para-1 */}
            <p className="para-1">By using our services, you agree not to engage in any activities that:</p>
            {/* list-level-i */}
            <ul className="list-level-i">
              {/* list-item */}
              <li className="list-item">Violate any applicable laws, regulations, or third-party rights;</li>
              {/* list-item */}
              <li className="list-item">Interfere with the normal functioning of our services or other users' enjoyment of our services;</li>
              {/* list-item */}
              <li className="list-item">Transmit harmful, offensive, or otherwise objectionable content;</li>
              {/* list-item */}
              <li className="list-item">Attempt to gain unauthorized access to our systems, accounts, or data;</li>
              {/* list-item */}
              <li className="list-item">Use our services for any illegal or unauthorized purpose.</li>
            </ul>
          </section>
          {/* Billing and Payments */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="billing-and-payments-section">5. Billing and Payments</h2>
            {/* para-1 */}
            <p className="para-1">You agree to pay any fees associated with your use of ArkadeONE Hosting services, as outlined in our pricing plans. You are responsible for providing accurate payment information and ensuring that any payment method on file is up-to-date. If payment is not received, we reserve the right to suspend or terminate your services.</p>
          </section>
          {/* Refund Policy */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="refund-policy-section">6. Refund Policy</h2>
            {/* para-1 */}
            <p className="para-1">We offer a refund policy for new customers within a specified timeframe, as detailed on our website. Refund requests must be made within this timeframe and are subject to our approval.</p>
          </section>
          {/* Intellectual Property */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="intellectual-property-section">7. Intellectual Property</h2>
            {/* para-1 */}
            <p className="para-1">All content and materials provided by ArkadeONE Hosting, including our website, GamePanel, and related software, are protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, distribute, or otherwise exploit our intellectual property without our prior written consent.</p>
          </section>
          {/* Limitation of Liability */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="limitation-of-liability-section">8. Limitation of Liability</h2>
            {/* para-1 */}
            <p className="para-1">To the maximum extent permitted by law, ArkadeONE Hosting shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of our services, even if we have been advised of the possibility of such damages.</p>
          </section>
          {/* Indemnification */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="indemnification-section">9. Indemnification</h2>
            {/* para-1 */}
            <p className="para-1">You agree to indemnify, defend, and hold harmless ArkadeONE Hosting and its affiliates, officers, directors, employees, and agents from any claims, damages, losses, or expenses, including reasonable attorney's fees, arising from your use of our services or violation of these Terms.</p>
          </section>
          {/* Governing Law and Jurisdiction */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="governing-law-and-jurisdiction-section">10. Governing Law and Jurisdiction</h2>
            {/* para-1 */}
            <p className="para-1">These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which ArkadeONE Hosting operates. Any disputes arising from these Terms or your use of our services shall be subject to the exclusive jurisdiction of the courts of that jurisdiction.</p>
          </section>
          {/* Changes to Terms of Service */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="changes-to-terms-of-service-section">11. Changes to Terms of Service</h2>
            {/* para-1 */}
            <p className="para-1">We reserve the right to update or modify these Terms at any time. Any changes to the Terms will be posted on our website, and your continued use of our services after such changes have been posted constitutes your acceptance of the modified Terms.</p>
          </section>
          {/* Contact Information */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="contact-information-section">12. Contact Information</h2>
            {/* para-1 */}
            <p className="para-1">If you have any questions or concerns about these Terms or our services, please contact us through the contact information provided on our website.</p>
          </section>
          {/* Content */}
          {/* title-1 */}
          <h1 className="title-1" id="privacy-policy-section">Privacy Policy</h1>
          {/* Introduction */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="introduction-privacy-section">1. Introduction</h2>
            {/* para-1 */}
            <p className="para-1">ArkadeONE Hosting is committed to protecting the privacy of our customers. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our services. By using our services, you consent to the collection and use of your personal information in accordance with this Privacy Policy.</p>
          </section>
          {/* Information We Collect */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="information-we-collect-section">2. Information We Collect</h2>
            {/* para-1 */}
            <p className="para-1">We collect various types of information from our customers, including:</p>
            {/* list-level-i */}
            <ul className="list-level-i">
              {/* list-item */}
              <li className="list-item">Personal Information: When you create an account or purchase our services, we may collect your name, email address, billing address, and payment information.</li>
              {/* list-item */}
              <li className="list-item">Usage Information: We automatically collect certain data related to your use of our services, such as IP address, browser type, and usage patterns.</li>
              {/* list-item */}
              <li className="list-item">Cookies: We use cookies and similar technologies to enhance your experience on our website and to track user behavior.</li>
            </ul>
          </section>
          {/* How We Use Your Information */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="how-we-use-your-information-section">3. How We Use Your Information</h2>
            {/* para-1 */}
            <p className="para-1">We use the information we collect for various purposes, including:</p>
            {/* list-level-i */}
            <ul className="list-level-i">
              {/* list-item */}
              <li className="list-item">Providing our services and customer support;</li>
              {/* list-item */}
              <li className="list-item">Processing transactions and sending billing information;</li>
              {/* list-item */}
              <li className="list-item">Communicating with you about updates, promotions, and other marketing materials;</li>
              {/* list-item */}
              <li className="list-item">Analyzing and improving our services;</li>
              {/* list-item */}
              <li className="list-item">Protecting our rights and the rights of our users;</li>
              {/* list-item */}
              <li className="list-item">Complying with legal obligations.</li>
            </ul>
          </section>
          {/* Sharing Your Information */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="sharing-your-information-section">4. Sharing Your Information</h2>
            {/* para-1 */}
            <p className="para-1">We do not sell or rent your personal information to third parties. However, we may share your information with third parties in the following situations:</p>
            {/* list-level-i */}
            <ul className="list-level-i">
              {/* list-item */}
              <li className="list-item">Service Providers: We may share your information with trusted service providers who help us deliver our services, such as payment processors and hosting providers.</li>
              {/* list-item */}
              <li className="list-item">Legal Requirements: We may disclose your information if required by law or if we believe that disclosure is necessary to protect our rights or the rights of others.</li>
              {/* list-item */}
              <li className="list-item">Business Transfers: If ArkadeONE Hosting is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
            </ul>
          </section>
          {/* Security */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="security-section">5. Security</h2>
            {/* para-1 */}
            <p className="para-1">We take reasonable measures to protect your personal information from unauthorized access, misuse, or disclosure. However, no method of data transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>
          </section>
          {/* Third-Party Websites */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="third-party-websites-section">6. Third-Party Websites</h2>
            {/* para-1 */}
            <p className="para-1">Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
          </section>
          {/* Children's Privacy */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="childrens-privacy-section">7. Children's Privacy</h2>
            {/* para-1 */}
            <p className="para-1">Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>
          </section>
          {/* Changes to Privacy Policy */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="changes-to-privacy-policy-section">8. Changes to Privacy Policy</h2>
            {/* para-1 */}
            <p className="para-1">We reserve the right to update or modify this Privacy Policy at any time. Any changes to the Privacy Policy will be posted on our website, and your continued use of our services after such changes have been posted constitutes your acceptance of the modified Privacy Policy.</p>
          </section>
          {/* Contact Information */}
          <section>
            {/* title-2 */}
            <h2 className="title-2" id="contact-information-privacy-section">9. Contact Information</h2>
            {/* para-1 */}
            <p className="para-1">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us through the contact information provided on our website.</p>
          </section>
        </div>
      </div>

      {/* footer */}
      <Footer/>


    </div>
    <ScrollToTopBtn />
    </>
  )
}
