import React from 'react';
import { Link } from "react-router-dom";

const Sectioncol = () => {
    return(
        <div className="container">
            <div className="row">

                <div className="col-lg-4 col-md-6 mb-sm-30">
                    <h3>Server Near You</h3>
                        <p>Discover a server close to you with our extensive network. Enjoy smooth and fast connections thanks to our high-performance servers, designed to minimize latency and maximize reliability. Experience seamless service wherever you are with our top-quality infrastructure.</p>
                        <Link className="btn-line lg-w-50" to="/">Choose a Server</Link>
                </div>
                <div className="col-lg-4 col-md-6 mb-sm-30">
                    <h3>Affiliates Program</h3>
                        <p>Join our affiliates program and start earning today. Benefit from competitive commissions, comprehensive support, and a user-friendly platform designed to help you succeed. Leverage our resources and network to maximize your earnings and grow with us.</p>
                        <Link className="btn-line lg-w-50" to="/">Become an Affiliate</Link>
                </div>
                <div className="col-lg-4 col-md-6 mb-sm-30">
                    <h3>Need Support?</h3>
                        <p>Our team is here to assist you with any questions or issues you may have. Reach out for help and get prompt, professional support tailored to your needs. Whether it’s technical assistance or general inquiries, we’re ready to provide solutions and ensure your satisfaction.</p>
                        <Link className="btn-line lg-w-50" to="/">Contact Support</Link>
                </div>
            </div>
        </div>
    );
}

export default Sectioncol;