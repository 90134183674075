import React from 'react';
import { Parallax } from "react-parallax";
import { Link } from 'react-router-dom';


const Download = () => {
    return(
        <div className="container">
            <div className="row position-relative">
                <div className="col-lg-12">
                    <Parallax className="padding60 sm-padding40" bgImage="./img/background/2.webp" strength={400}> 
                        <div className="col-lg-6">
                            <div className="subtitle wow fadeInUp mb-3">Download now</div>
                            <h2 className="wow fadeInUp" data-wow-delay=".2s"><span className="text-gradient">Access</span> and manage all your server settings.</h2>
                            <p>You can monitor performance, configure security settings, and handle updates seamlessly. Ensure your server is optimized and running smoothly, even on the go. Maintain full control from any location with detailed stats and notifications available in real-time for instant decisions.</p>                            
                            <Link to="/"><img width="180" height="100" src="../../img/misc/download-appstore.webp" className="img-fluid mb-sm-20" alt="download"/></Link>&nbsp;
                            <Link to="/"><img width="180" height="100" src="../../img/misc/download-playstore.webp" className="img-fluid mb-sm-20" alt="download"/></Link>
                        </div>
                    </Parallax>
                </div>
            </div>
        </div>
    );
}

export default Download;