import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Parallax } from "react-parallax";
import Navbar from '../layout/Navbar';
import Preloader from '../layout/preloader';
import Reviews from '../section-pages/CustomerReviews';
import Footer from '../section-pages/footer';
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';

const image1 ="./img/background/subheader-about.webp";

const GlobalStyles = createGlobalStyle`

`;

export default function Home() {
  useEffect(() => {
      if (typeof window !== 'undefined') {
          const loader = document.getElementById('mainpreloader');
          if (loader)
          setTimeout(() => {
            loader.classList.add("fadeOut");
            loader.style.display = 'none';
          }, 600)
      }
    }, []);
  return (
    <>
    {/* HEAD */}
    <Helmet>
      <link rel="icon" href="https://cdn.arkade.one/files/assets/img/favicon.png" />
      <title>ArkadeONE - About Us</title>
    </Helmet>

    <GlobalStyles/>

    {/* LOADER */}
    <div id='mainpreloader'>
      <Preloader/>
    </div>

    {/* MENU */}
    <div className="home dark-scheme">
      <header id="header-wrap">
         <Navbar />
      </header>

      {/* section */}
      <Parallax className="" bgImage={image1} strength={5}>  
        <section className="no-bg">
          <div className="container z-9">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="subtitle  mb-3">About us</div>
                  </div>
                  <div className="col-lg-6">
                      <h2 className=" mb20" data-wow-delay=".2s">This is our story</h2>
                  </div>                 
              </div>
          </div>
        </section>
      </Parallax>

      {/* section */}
      <section>
        <div className="container">
              <div className="row align-items-center gh-5">
                  <div className="col-lg-6 position-relative">
                      <div className="images-deco-1">
                          <img src="./img/misc/building.webp" className="d-img-1" alt=""/>
                          <img src="./img/misc/girl-ai.webp" className="d-img-2" alt=""/>
                          <div className="d-img-3 bg-color"></div>
                      </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="subtitle mb20">We are ArkadeONE</div>
                      <h2 >The beginning</h2>
                      <p >Welcome to ArkadeONE, your ultimate destination for high-performance game server hosting! Our journey began three years ago with a simple mission: to create a seamless and enjoyable gaming experience for everyone. Since then, we’ve been dedicated to providing top-notch game server solutions that combine performance, affordability, and accessibility. Our team is led by three passionate directors: Jesse B. (CEO & Director of HMFIC), the strategic mastermind driving our vision forward; Lennox M. (CEO & Director of Development), the technical genius ensuring our servers run smoothly; and Kane T. (Director of Human Resources), who fosters a positive and inclusive work environment. At ArkadeONE, we believe that gaming should be fun, accessible, and hassle-free. Join us and experience the perfect blend of performance, affordability, and community. By Gamers, For Gamers!</p>
                      <div className="year-card ">
                          <h1><span className="id-color">7</span></h1>
                          <div className="atr-desc">Years<br/>Experience<br/>Hosting</div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      {/* section */}
      <section className="no-top">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="row gx-5">
                        <div className="col-lg-6 col-md-6">
                            <h4>Our Vision</h4>
                            <p>At ArkadeONE, our vision is to revolutionize the gaming experience by providing unparalleled game server hosting that is both accessible and affordable. We strive to create a community where gamers of all levels can enjoy seamless, high-performance gameplay without the hassle. Our commitment to innovation, quality, and customer satisfaction drives us to continuously improve and expand our services. We envision a future where ArkadeONE is synonymous with excellence in game hosting, empowering gamers worldwide to connect, compete, and thrive. Join us on this exciting journey and let’s shape the future of gaming together!</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <h4>Our Mission</h4>
                            <p>At ArkadeONE, our mission is to make gaming with friends seamless and enjoyable for everyone, without compromising on quality. We are dedicated to providing high-performance game server hosting that is both accessible and affordable. Our goal is to create a platform where gamers can connect, compete, and thrive in a supportive and inclusive community. By continuously innovating and improving our services, we aim to set new standards in the gaming industry and ensure that every player has the best possible experience. Join us and be a part of the future of gaming!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>

      {/* section */}
      <section className="no-top">
        <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <div className="subtitle mb20">Our solid team</div>
                  <h2 className="mb20 wow fadeInUp">Behind the scene</h2>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb-sm-30">
                  <div className="f-profile text-center">
                      <div className="fp-wrap f-invert">                                
                          <div className="fpw-overlay-btm"></div>
                          <img src="./img/team/2.webp" className="fp-image img-fluid" alt=""/>
                      </div>

                      <h4>Jesse B.</h4>
                      CEO Founder
                  </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb-sm-30">
                  <div className="f-profile text-center">
                      <div className="fp-wrap f-invert">                              
                          <div className="fpw-overlay-btm"></div>
                          <img src="./img/team/1.webp" className="fp-image img-fluid" alt=""/>
                      </div>

                      <h4>Lennox M.</h4>
                      CEO Founder
                  </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb-sm-30">
                  <div className="f-profile text-center">
                      <div className="fp-wrap f-invert">                              
                          <div className="fpw-overlay-btm"></div>
                          <img src="./img/team/3.webp" className="fp-image img-fluid" alt=""/>
                      </div>

                      <h4>Kane T.</h4>
                      Human Ressources
                  </div>
              </div>

          </div>
        </div>
      </section>

      {/* section */}
      <section className="no-top">
       <Reviews/>
      </section>

      {/*
      <section className="no-top">
       <div className="container">
            <div className="row text-center">
                <div className="col-lg-3 col-sm-6 mb-sm-30 position-relative">
                    <div className="de_count text-light wow fadeInUp">
                        <h3 className="timer id-color" >15425</h3>
                        <h4 className="text-uppercase">Happy<br/>Gamers</h4>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 mb-sm-30 position-relative">
                    <div className="de_count text-light wow fadeInUp">
                        <h3 className="timer text-line">8745</h3>
                        <h4 className="text-uppercase">Servers<br/>Ordered</h4>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 mb-sm-30 position-relative">
                    <div className="de_count text-light wow fadeInUp">
                        <h3 className="timer id-color">235</h3>
                        <h4 className="text-uppercase">Awards<br/>Winning</h4>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 mb-sm-30 position-relative">
                    <div className="de_count text-light wow fadeInUp">
                        <h3 className="timer text-line">15</h3>
                        <h4 className="text-uppercase">Years<br/>Experience</h4>
                    </div>
                </div>
            </div>
        </div>
      </section>*/}


      <Footer/>


    </div>
    <ScrollToTopBtn />
    </>
  )
}
