import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Customerreviews = () => {
  return (
    <>
    <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="subtitle mb20">Customer reviews</div>
                    <h2 className="wow fadeInUp">4.6 out of 5</h2>
                    <div className="spacer-20"></div>
                </div>
            </div>
        </div>
    <Swiper className="smallslider"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={20}
      slidesPerView="auto"
      pagination={{ clickable: true }}
      centeredSlides
      loop
      slideToClickedSlide
      >
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
              <blockquote>
                  <div className="de-rating-ext">
                      <span className="d-stars">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                      </span>
                  </div>
                  <p>&quot;It also doesn't cost much, the servers are also relatively powerful and the support helps you with any problem you have.
If I were you, I would get a server from CubeHosting and give them a try.
Another plus point is that there is a free server.&quot;
                  </p>
                  <div className="de_testi_by">
                      <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/01.png"/> <span>Joniii</span>
                  </div>
              </blockquote>
          </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;It's perfect, I get support in less than 5 minutes, & it's easy to manage&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/06.png"/> <span>Mark</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;I am impressed by the website, I did not think that such a website would still exist, and there is very nice and personable support here&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/03.png"/> <span>Energy Play</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                  <blockquote>
                      <div className="de-rating-ext">
                          <span className="d-stars">
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                          </span>
                      </div>
                      <p>Is a server at ArkadeONE worth it?

The answer to this question is yes! You can order cheap servers that already have a lot of power. With the more advanced plans you can upgrade your server to more ram and memory. Also you can download plugins easily&quot;</p>
                      <div className="de_testi_by">
                          <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/05.png"/> <span>Manfred TV</span>
                      </div>
                  </blockquote>
              </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;This website gave me excellent help even when im on a cracked account they helped me out jus excellent!&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/07.png"/> <span>Bryan Descan</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Very good for the price ,fast server really good panel and just overall so good as a server host the supportt is very fast and helpful&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/02.png"/> <span>val iscool</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Best host so far. I'm making a bedrock and java edition bungeecord SMP. No hassle, so far it's better than other Hosts. Best part is, that this host actually stays unlike the other. You see, some hosts have cheap prices, etc but they shut down it maybe after 1 month. This just isn't the case for ArkadeONE. 10/10 will recommend to beginners&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/04.png"/> <span>Fearfull</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;i loved it because its cheap and fast. Also everyone can use the panel because is very easy to use&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="https://cdn.arkade.one/files/assets/images/avatars/08.png"/> <span>
                        juan roldan</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
    </Swiper>
    </>
  );
};
export default Customerreviews;