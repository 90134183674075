import React from 'react';
import { Parallax } from 'react-parallax';
import YouTubePlayer from './youtubeplayer';
import { Link } from "react-router-dom";


const Section = () => {
   const videoId = 'fWwNNTjZiY0';
    return(
        <Parallax className="home-video">
                <div className="iframeyoutube"><YouTubePlayer videoId={videoId} /></div>
                
                <div className="de-gradient-edge-top"></div>
                <div className="de-gradient-edge-bottom"></div>
                <div className="overlay-bg t50 no-top no-bottom">
                    <div className="v-center">
                        <div className="container z-1000">
                            <div className="row align-items-center">                        
                                <div className="col-lg-10 offset-lg-1 text-center">
                                    <div className="subtitle blink mb-4">Back Soon!</div>
                                    <h1 className="mb-0 ">Under Maintenance</h1>                                    
                                </div>
                                <div className="col-lg-6 offset-lg-3 text-center text-white">
                                    <p className="">Our services are currently undergoing maintenance to improve performance and reliability. We’re working hard to minimize downtime and ensure everything is back up and running smoothly. Thank you for your patience during this process.</p>
                                    <Link className="btn-main" to="/contact">Get in Touch</Link>

                                    <div className="spacer-single"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Parallax>
    );
}

export default Section;