import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home1 from './pages/home';
import Soon from '../src/pages/Soon'
import Location from '../src/pages/location';
import Faq from '../src/pages/faq';
import Contact from '../src/pages/contact';
import News from '../src/pages/news';
import About from '../src/pages/about';
import Affiliate from '../src/pages/affiliate';
import Games from '../src/pages/games'
import Terms from '../src/pages/terms'

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const routes = [
  { path: '/', element: <Home1 /> },
  { path: '/games', element: <Games/> },
  { path: '/soon', element: <Soon /> },
  { path: '/locations', element: <Location /> },
  { path: '/faq', element: <Faq /> },
  { path: '/contact', element: <Contact /> },
  { path: '/news', element: <News /> },
  { path: '/about', element: <About /> },
  { path: '/affiliate', element: <Affiliate /> },
  { path: '/terms', element: <Terms /> },
];

const Navigation = () => (
  <Routes>
    {routes.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ))}
  </Routes>
);

function App() {
  return (
    <HelmetProvider>
      <div>
        <BrowserRouter>
          <ScrollToTop />
          <Navigation />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
