import React from 'react';
import { Link } from 'react-router-dom';

const collection = () => {
    return(
        <div className="container">
            <div className="row">
              <div className="col-md-6">
                  <div className="subtitle mb20">More than 16 games!</div>
                  <h2 className="wow fadeInUp">Our Game Collection</h2>
                  <div className="spacer-20"></div>
              </div>
              <div className="col-lg-6 text-lg-end">
                  <Link className="btn-main mb-sm-30" to="/games">View all games</Link>
              </div>
            </div>
            <div className="row g-4 sequence">

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>Minecraft</h4>
                            <p className="d-price">Starting at <span className="price">$3.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img   style={{
        width: '301px',
        height: '401.33px',
    backgroundSize: 'cover'
  }} src="https://vollversion-kaufen.de/tpl/images/produkte/MinecraftJavaEditionPCCDKey.webp" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>Unturned</h4>
                            <p className="d-price">Starting at <span className="price">$4.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img style={{
        width: '301px',
        height: '401.33px',
    backgroundSize: 'cover'
  }} src="https://store-images.s-microsoft.com/image/apps.49692.14053918881081096.5c7f869e-3a72-49b1-bee7-43f4b51af93c.e7fc2e96-c6e3-45e5-aba6-2e279e3466b0" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>Counter Strike</h4>
                            <p className="d-price">Starting at <span className="price">$4.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img 
                    style={{
                        width: '301px',
                        height: '401.33px',
                    backgroundSize: 'cover'
                  }}
                  src="https://de.egamersworld.com/_next/image?url=https%3A%2F%2Fegamersworld.com%2Fuploads%2Fblog%2F1696414021417.jpg&w=1920&q=75" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>Rust</h4>
                            <p className="d-price">Starting at <span className="price">$4.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img 
                    style={{
                        width: '301px',
                        height: '401.33px',
                    backgroundSize: 'cover'
                  }}
                  
                  src="https://assets-prd.ignimgs.com/2021/12/07/rust-1638841834256.png" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>garry's mod</h4>
                            <p className="d-price">Starting at <span className="price">$4.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img 
                    style={{
                        width: '301px',
                        height: '401.33px',
                    backgroundSize: 'cover'
                  }}
                  src="https://assets-prd.ignimgs.com/2022/07/09/garrysmod-1657387262990.jpg" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>Valheim</h4>
                            <p className="d-price">Starting at <span className="price">$4.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img 
                    style={{
                        width: '301px',
                        height: '401.33px',
                    backgroundSize: 'cover'
                  }}
                  src="https://www.igroshop.com/images/detailed/13/valheim_box_art.png" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>DayZ</h4>
                            <p className="d-price">Starting at <span className="price">$4.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img 
                    style={{
                        width: '301px',
                        height: '401.33px',
                    backgroundSize: 'cover'
                  }}
                  src="https://image.api.playstation.com/vulcan/ap/rnd/202301/0312/z9AGStZnUt3o3nbxJUrCjkyM.png" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item sandbox">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                            70% OFF
                        </div>
                        <div className="d-text">
                            <h4>Squad</h4>
                            <p className="d-price">Starting at <span className="price">$4.00</span></p>
                            <Link className="btn-main btn-fullwidth" to="https://my.arkade.one/order">Order Now</Link>
                        </div>
                    </div>
                    <img 
                     style={{
                        width: '301px',
                        height: '401.33px',
                    backgroundSize: 'cover'
                  }}
                  src="https://gpstatic.com/acache/36/69/1/de/packshot-5762746b2a75a4ce0699d53067329ad7.jpg" className="img-fluid" alt=""/>
                </div>
            </div>

            </div>

        </div>
    );
}

export default collection;