import React from 'react';
import { Navigation, Pagination, Autoplay, Thumbs, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";


const YourComponent = () => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  return (
    <div className="doubleslider">
    <Swiper
      className="mainslider"
      thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
      modules={[Navigation, Pagination, Autoplay, Thumbs]}
      loop={true}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000 }}
    >
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("./img/slider/5.webp")`,
                                              width: '100%',
                                              height: '100%',
                                              backgroundSize: 'cover'
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center text-center">
                        <div className="col-lg-8 mb-sm-30 mx-auto">
                            <h1 className="slider-title text-uppercase mb-1">Minecraft - 1.21.0</h1>
                        </div>
                        <div className="col-lg-6 mx-auto">
                            <p className="slider-text text-white">Build anything you can imagine, uncover eerie mysteries, and survive the night in the ultimate sandbox game. In Minecraft, every playthrough is different, and unforgettable adventures await behind every corner. Explore and craft your way through an infinite world that’s yours to shape, one block at a time.</p>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="/">Order Your Game Server Now</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sw-overlay"></div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("./img/slider/6.webp")`,
                                              width: '100%',
                                              height: '100%',
                                              backgroundSize: 'cover'
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center text-center">
                        <div className="col-lg-8 mb-sm-30 mx-auto">
                            <h1 className="slider-title text-uppercase mb-1">Unturned</h1>
                        </div>
                        <div className="col-lg-6 mx-auto">
                            <p className="slider-text text-white">You're a survivor in the zombie infested ruins of society, and must work with your friends and forge alliances to remain among the living.</p>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="/">Order Your Game Server Now</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sw-overlay"></div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("./img/slider/7.webp")`,
                                              width: '100%',
                                              height: '100%',
                                              backgroundSize: 'cover'
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center text-center">
                        <div className="col-lg-8 mb-sm-30 mx-auto">
                            <h1 className="slider-title text-uppercase mb-1">Rust</h1>
                        </div>
                        <div className="col-lg-6 mx-auto">
                            <p className="slider-text text-white">The only aim in Rust is to survive. Everything wants you to die - the island’s wildlife and other inhabitants, the environment, other survivors. Do whatever it takes to last another night.</p>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="/">Order Your Game Server Now</Link>
                        </div>
                    </div>
                </div>
            </div>                                
            <div className="sw-overlay"></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("https://steamuserimages-a.akamaihd.net/ugc/2032851493626593123/F3CBC45E1D9363116625D789AAC418BB53916A56/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false")`,
                                              width: '100%',
                                              height: '100%',
                                               backgroundSize: 'cover'
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center text-center">
                        <div className="col-lg-8 mb-sm-30 mx-auto">
                            <h1 className="slider-title text-uppercase mb-1">Garry's Mod</h1>
                        </div>
                        <div className="col-lg-6 mx-auto">
                            <p className="slider-text text-white">Garry's Mod is a physics sandbox. There aren't any predefined aims or goals. We give you the tools and leave you to play.</p>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="/">Order Your Game Server Now</Link>
                        </div>
                    </div>
                </div>
            </div>                                
            <div className="sw-overlay"></div>
        </div>
      </SwiperSlide>
      {/* Add more slides here */}
    </Swiper>
    <Swiper
        direction={'vertical'}
        onSwiper={setThumbsSwiper}
        
        freeMode={true}
        spaceBetween={10}
        slidesPerView={3}
        modules={[FreeMode, Navigation, Thumbs]}
        className="thumb-slider"
      >
        <SwiperSlide className="swiper-slide" style={{
                                              backgroundImage: `url("./img/slider/5.webp")`,
                                            }}>
          <div className="sw-caption-thumb">
              <span className="d-tag">
                  15% OFF
              </span>
              <h3>Minecraft</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide" style={{
                                              backgroundImage: `url("./img/slider/6.webp")`,
                                            }}>
          <div className="sw-caption-thumb">
              <span className="d-tag">
                  15% OFF
              </span>
              <h3>Unturned</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide" style={{
                                              backgroundImage: `url("./img/slider/7.webp")`,
                                            }}>
          <div className="sw-caption-thumb">
              <span className="d-tag">
                  15% OFF
              </span>
              <h3>Rust</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide" style={{
                                              backgroundImage: `url("./img/slider/8.webp")`,
                                            }}>
          <div className="sw-caption-thumb">
              <span className="d-tag">
                  15% OFF
              </span>
              <h3>Garry's Mod</h3>
          </div>
        </SwiperSlide>
        {/* Add more thumbnail slides here */}
      </Swiper>
    </div>
  );
};

export default YourComponent;
