import React from 'react';
import { Link } from "react-router-dom";

const footer = () => {
    const currentYear = new Date().getFullYear();
    return(
        <footer>
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-4">
                        <img src="./img/logo.png" style={{ height: '52px', width: '222px' }} alt="" />
                        <div className="spacer-20"></div>
                        <p>Gaming should be accessible to everyone, regardless of their financial situation. That's why we created this platform.</p>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <div className="widget">
                                    <h5>Producs</h5>
                                    <ul>
                                        <li><Link to="/">Gameserver</Link></li>
                                        <li><Link to="/">Developer Packages</Link></li>
                                        <li><Link to="/">Status Page</Link></li>
                                        <li><Link to="/">Mobile App</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="widget">
                                    <h5>Company</h5>
                                    <ul>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><Link to="/">Locations</Link></li>
                                        <li><Link to="/">Affliate Programm</Link></li>
                                        <li><Link to="https://cdn.arkade.one/files/press.zip">Media & Presskit</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <form action="blank.php" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                            <div className="col text-center">
                                <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> <Link to="/" id="btn-subscribe"><i className="arrow_right bg-color-secondary"></i></Link>
                                <div className="clearfix"></div>
                            </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don&apos;t spam.</small>
                            <div className="spacer-30"></div>
                            <div className="widget">
                                <h5>Follow Us on</h5>
                                <div className="social-icons">
                                    <Link to="https://www.instagram.com/ArkadeONE/"><i className="fa-brands fa-instagram"></i></Link>
                                    <Link to="https://x.com/ArkadeONE"><i className="fa-brands fa-x"></i></Link>
                                    <Link to="https://discord.gg/arkadeone-host-play-mod-share-967431124764745800"><i className="fa-brands fa-discord"></i></Link>
                                    <Link to="https://www.tiktok.com/@arkadeone"><i className="fa-brands fa-tiktok"></i></Link>
                                    <Link to="https://www.youtube.com/@ArkadeONE"><i className="fa-brands fa-youtube"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            ArkadeONE LLC | V29824A4 © 2021 - {currentYear}
                        </div>
                        <div className="col-lg-6 col-sm-6 text-lg-end text-sm-start">
                            <ul className="menu-simple">
                                <li><Link to="/">Terms &amp; Conditions</Link></li>
                                <li><Link to="/">Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default footer;